import { useEffect, useState } from "react";
import logo from "../../logo_n2.png";
import { Link } from "react-router-dom";

function NavBar() {
  const [emailCopied, setEmailCopied] = useState(false);

  useEffect(() => {
    const scroller = () => {
      const classList = ["h-16", "border-b-2"];
      const iconClassList = ["scale-75"];
      const quoteClassList = ["hidden"];
      const linkClassList = ["text-base"];

      if (window.scrollY > 64) {
        classList.map((cls) =>
          document.getElementById("nav").classList.add(cls)
        );
        iconClassList.map((cls) =>
          document.getElementById("icon").classList.add(cls)
        );
        quoteClassList.map((cls) =>
          document.getElementById("quote").classList.add(cls)
        );
        linkClassList.map((cls) =>
          document.getElementById("link").classList.add(cls)
        );
      } else {
        classList.map((cls) =>
          document.getElementById("nav").classList.remove(cls)
        );
        iconClassList.map((cls) =>
          document.getElementById("icon").classList.remove(cls)
        );
        quoteClassList.map((cls) =>
          document.getElementById("quote").classList.remove(cls)
        );
        linkClassList.map((cls) =>
          document.getElementById("link").classList.remove(cls)
        );
      }
    };
    window.addEventListener("scroll", scroller);
    return () => window.removeEventListener("scroll", scroller);
  }, []);

  return (
    <div
      className='fixed w-screen z-50 top-0 text-white transition-all duration-300 ease-linear bg-sky-500 bg-clip-padding backdrop-filter bg-opacity-50'
      id='nav'
    >
      <div
        className=' py-1 text-sm font-extrabold text-blue-900 bg-sky-400 transition-all duration-1000 ease-linear'
        id='quote'
      >
        <div className='text-center'>
          We create wonders with workforce transformation.! A women-owned
          minority business enterprise based in Texas.
        </div>
      </div>
      <div className='grid grid-flow-col text-xs md:text-lg h-16 p-1'>
        <Link
          to='/'
          className='flex flex-no-shrink justify-center col-span-2 '
          onClick={scrollToTpp}
        >
          <img
            src={logo}
            className='h-12 lg:h-14 ml-4 p-2 transition-all duration-300 ease-linear bg-white rounded-2xl'
            alt={"qm_logo"}
            id='icon'
          />
        </Link>

        <div className='col-span-10'>
          <span className='hidden lg:block h-full'>
            <DesktopMenu />
          </span>
          <span className='block lg:hidden h-full'>
            <MobileMenu />
          </span>
        </div>
      </div>
    </div>
  );
}

const MobileMenu = () => {
  return (
    <>
      <div
        tabIndex={0}
        role='button'
        className='btn btn-ghost lg:hidden grid grid-flow-col justify-end '
        onClick={() => {
          document.getElementById("m-menu").classList.toggle("hidden");
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-8 w-8'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M4 6h16M4 12h8m-8 6h16'
          />
        </svg>
      </div>
      <ul
        className='fixed bg-white w-screen h-screen left-0 hidden text-xl font-bold text-sky-400'
        id='m-menu'
      >
        <MenuLink link='/' text='Home' />
        <MenuLink
          link={[
            { text: "Recruitment Process Outsourcing", link: "/ps" },
            { text: "Digital Transformation Solutions", link: "/dt" },
            { text: "QA services ", link: "/qa" },
            { text: "Design Solutions", link: "/sd" },
          ]}
          text='Services'
        />
        <MenuLink link='/about' text='About us' />
        <Apppointment />
      </ul>
    </>
  );
};

const DesktopMenu = () => {
  return (
    <ul
      className='menu navbar border-b md:border-none grid grid-flow-col justify-end m-0 w-full md:w-auto p-0 pr-8 font-extrabold'
      id='link'
    >
      <MenuLink link='/' text='Home' />
      <MenuLink
        link={[
          { text: "Recruitment Outsourcing", link: "/ps" },
          { text: "Digital Transformation", link: "/dt" },
          { text: "QA services ", link: "/qa" },
          { text: "Design Solutions", link: "/sd" },
        ]}
        text='Services'
      />
      <MenuLink link='/about' text='About us' />
      <Apppointment />
    </ul>
  );
};

const MenuLink = ({ link, text }) => {
  if (link instanceof Array) {
    return (
      <div className='dropdown'>
        <div
          tabIndex={0}
          role='button'
          className='px-4 py-3 no-underline transition-all duration-300'
        >
          {text}
        </div>
        <ul
          tabIndex={0}
          className='menu dropdown-content z-[10] p-2 shadow bg-white text-sky-400 rounded-box w-screen lg:w-64 mt-4'
        >
          {link.map((item) => (
            <li
              onClick={() => {
                document.getElementById("m-menu").classList.add("hidden");
              }}
            >
              <Link to={item.link} onClick={scrollToTpp}>
                <span className='text-blue-800'>{">"}</span>
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  } else {
    return (
      <li
        className='border-t md:border-none'
        onClick={() => {
          document.getElementById("m-menu").classList.add("hidden");
        }}
      >
        <Link
          to={link}
          className='block md:inline-block px-4 py-3 no-underline transition-all duration-300'
          key={text}
          onClick={scrollToTpp}
        >
          {text}
        </Link>
      </li>
    );
  }
};

const Apppointment = () => {
  return (
    <div className='mt-8 lg:mt-0 h-12'>
      <a
        href='#contact'
        className='relative px-5 py-3 overflow-hidden font-medium text-gray-600 bg-gray-100 rounded-lg shadow-inner group'
        onClick={() => {
          document.getElementById("m-menu").classList.add("hidden");
        }}
      >
        <span className='absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease'></span>
        <span className='absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease'></span>
        <span className='absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-sky-600 group-hover:h-full ease'></span>
        <span className='absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-sky-600 group-hover:h-full ease'></span>
        <span className='absolute inset-0 w-full h-full duration-300 delay-300 bg-sky-800 opacity-0 group-hover:opacity-100'></span>
        <span className='relative transition-colors duration-300 delay-200 group-hover:text-white ease'>
          Book Appoinment
        </span>
      </a>
    </div>
  );
};

export const scrollToTpp = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

export default NavBar;
