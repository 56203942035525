const AboutUs = () => {
  return (
    <>
      <AVM />
      <Accordian />
    </>
  );
};

const AVM = () => {
  return (
    <ul className='timeline timeline-snap-icon max-md:timeline-compact timeline-vertical mt-20 mb-32'>
      <li>
        <div className='timeline-middle'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            className='h-5 w-5 text-red-500'
          >
            <path
              fillRule='evenodd'
              d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        <div className='timeline-start md:text-end mb-10 rounded-xl bg-sky-300 px-4 py-10 text-slate-700'>
          <h1 className='text-lg font-black pb-4'>About Us</h1>
          <div className='text-start indent-20'>
            Qualmission LLC., is an IT Consulting and Services company that
            helps Businesses and Organizations meet their complex requirements
            and changing needs by providing expert Technical Solutions and
            Services. Central to Qualmission’s strategy and policy is to provide
            our clients with unparalleled IT solutions and staffing services.
            Integral to that policy, as an IT staffing services provider we
            place highly qualified and motivated IT professionals with companies
            and organizations nationwide. We at Qualmission, offer a wide range
            of staffing options and solutions for the technological needs of our
            customers.
          </div>
        </div>
        <hr />
      </li>
      <li>
        <hr />
        <div className='timeline-middle'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            className='h-5 w-5 text-red-500'
          >
            <path
              fillRule='evenodd'
              d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        <div className='timeline-end mb-10 rounded-xl bg-sky-300 px-4 py-10 text-slate-700'>
          <h1 className='text-lg font-black pb-4'>Vision</h1>
          <p className='indent-20'>
            Leading the Way in IT Excellence. We aim to be a top-tier IT
            staffing firm, envisioning a future where diversity thrives,
            fostering an environment where everyone contributes to shaping the
            technology landscape.
          </p>
        </div>
        <hr />
      </li>
      <li>
        <hr />
        <div className='timeline-middle'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            className='h-5 w-5 text-red-500'
          >
            <path
              fillRule='evenodd'
              d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        <div className='timeline-start md:text-end mb-10 rounded-xl bg-sky-300 px-4 py-10 text-slate-700'>
          <h1 className='text-lg font-black pb-4'>Mission</h1>
          <p className='text-start indent-20'>
            Our mission is to deliver top-notch IT staffing solutions with
            meticulous candidate screening, saving clients valuable time. We're
            dedicated to creating opportunities for all, promoting diversity
            within the tech industry, and striving for excellence in every
            aspect of our work.
          </p>
        </div>
      </li>
    </ul>
  );
};

const Accordian = () => {
  const orgValue = [
    {
      name: "Integrity",
      desc: "Doing what's right, always, and making ethical choices in everything we do.",
    },
    {
      name: "Excellence",
      desc: "Always aiming for the best quality in our services, making sure we meet high standards.",
    },
    {
      name: "Diversity and Inclusion",
      desc: "Embracing and celebrating differences, creating a welcoming and diverse workplace.",
    },
    {
      name: "Innovation",
      desc: "Finding smart and creative ways to solve problems and keep up with our clients' changing needs.",
    },
    {
      name: "Customer Focus",
      desc: "Making our clients happy is our top priority, going above and beyond their expectations.",
    },
    {
      name: "Teamwork",
      desc: "Working together like a well-oiled machine, valuing each team member's unique strengths to reach our goals.",
    },
  ];
  return (
    <div className='grid justify-center place-items-center my-8'>
      {orgValue.map((el, index) => (
        <div
          tabIndex={0}
          key={index}
          className='collapse bg-sky-800 text-slate-200 focus:bg-sky-500 focus:text-white w-10/12 m-2 collapse-arrow transition-all duration-500 ease-in-out'
        >
          <div className='collapse-title text-xl'>{el.name}</div>
          <div className='collapse-content'>
            <p className='indent-20'>{el.desc}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default AboutUs;
