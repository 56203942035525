import { Link } from "react-router-dom";
import { scrollToTpp } from "../navbar";
import {
  FaTwitter,
  FaLinkedin,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className='relative grid place-items-center p-4 text-sm bg-sky-500 text-white z-40'>
      <div className='grid place-items-center w-full'>
        <div className='flex gap-2'>
          <div className='font-bold'>Qualmission</div>
          Copyright © 2024 - All right reserved
        </div>
        <Link className='underline' to='/privacy-policy' onClick={scrollToTpp}>
          Privacy Policy
        </Link>
        <nav className='flex gap-4 pt-4'>
          <Link
            to='https://www.linkedin.com/company/qualmission'
            target={"_blank"}
          >
            <FaLinkedin className='w-6 h-6' />
          </Link>
          <Link to='https://twitter.com/qualmission' target={"_blank"}>
            <FaTwitter className='w-6 h-6' />
          </Link>
          <Link
            to='https://www.facebook.com/profile.php?id=100091598538011'
            target={"_blank"}
          >
            <FaFacebook className='w-6 h-6' />
          </Link>
          <Link to='https://www.instagram.com/qualmission/' target={"_blank"}>
            <FaInstagram className='w-6 h-6' />
          </Link>
        </nav>
        g{" "}
      </div>
    </footer>
  );
};

export default Footer;
