import { Link } from "react-router-dom";
import { scrollToTpp } from "../navbar";

const Card = ({ img, title, link }) => {
  return (
    <Link
      to={link}
      data-aos='fade-up'
      onClick={scrollToTpp}
      className='relative m-4 w-64 h-64 rounded-full overflow-hidden transition-all duration-300 ease-linear hover:scale-110 grid justify-items-center place-content-center border-8 border-double border-sky-200 hover:bg-slate-700 hover:text-slate-100 hover:shadow-xl hover:border-sky-400'
    >
      <img
        src={img}
        className='absolute top-0 left-0 object-cover z-10 h-full w-full'
      />

      <div className='absolute w-screen h-screen z-20 flex items-center justify-center opacity-50 bg-black'></div>
      <div className='relative z-30 flex items-center justify-center'>
        <h1 className='font-bold text-xl grid place-content-center justify-items-center text-center text-white'>
          {title}
        </h1>
      </div>
    </Link>
  );
};

export default Card;
