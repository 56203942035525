function Privacy() {
  return (
    <div className='px-16 pt-32 pb-16 leading-9 text-lg text-gray-700 tracking-wide bg-slate-100'>
      <h1 className='text-4xl font-bold mb-4 text-gray-800'>Privacy Policy</h1>
      <p>
        <strong>Effective date:</strong> 09/22/2022
      </p>

      <h2 className='text-2xl mt-4 font-bold text-gray-800'>1. Introduction</h2>
      <p className='indent-20'>
        Qualmission is committed to protecting the privacy and security of your
        personal information. This Privacy Policy outlines how we collect, use,
        disclose, and safeguard your personal data when you visit our website,
        use our services, or interact with us.
      </p>

      <h2 className='text-2xl mt-4 font-bold text-gray-800'>
        2. Information We Collect
      </h2>
      <p className='indent-20'>
        We may collect various types of personal information, including but not
        limited to:
      </p>
      <ul className='list-disc list-inside ml-4'>
        <li>Contact Information (e.g., name, email address, phone number)</li>
        <li>Demographic Information (e.g., age, gender)</li>
        <li>Financial Information (e.g., payment details)</li>
        <li>Usage Data (e.g., website usage, preferences)</li>
        <li>Location Data (e.g., IP address, geolocation)</li>
        <li>Other information you voluntarily provide to us</li>
      </ul>

      <h2 className='text-2xl mt-4 font-bold text-gray-800'>
        3. How We Use Your Information
      </h2>
      <p className='indent-20'>
        We may use your personal information for purposes including, but not
        limited to:
      </p>
      <ul className='list-disc list-inside ml-4'>
        <li>Providing and improving our products and services</li>
        <li>Communicating with you and responding to your inquiries</li>
        <li>Processing payments and transactions</li>
        <li>Personalizing your experience</li>
        <li>Analyzing website traffic and user behavior</li>
        <li>Compliance with legal obligations</li>
      </ul>

      <h2 className='text-2xl mt-4 font-bold text-gray-800'>
        4. Cookies and Similar Technologies
      </h2>
      <p className='indent-20'>
        We may use cookies and similar technologies to collect and store
        information about your usage of our website. You can control cookies
        through your browser settings.
      </p>

      <h2 className='text-2xl mt-4 font-bold text-gray-800'>5. Data Sharing</h2>
      <p className='indent-20'>
        We may share your information with third parties, including service
        providers, business partners, and in compliance with legal requirements.
      </p>

      <h2 className='text-2xl mt-4 font-bold text-gray-800'>
        6. Data Security
      </h2>
      <p className='indent-20'>
        We implement reasonable security measures to protect your personal
        information from unauthorized access, disclosure, or alteration.
      </p>

      <h2 className='text-2xl mt-4 font-bold text-gray-800'>7. Your Choices</h2>
      <p className='indent-20'>
        You have rights regarding your personal information, including the right
        to access, update, or delete it. You can also opt out of marketing
        communications.
      </p>

      <h2 className='text-2xl mt-4 font-bold text-gray-800'>
        8. Children's Privacy
      </h2>
      <p className='indent-20'>
        Our services are not intended for individuals under the age of 13. We do
        not knowingly collect personal information from children.
      </p>

      <h2 className='text-2xl mt-4 font-bold text-gray-800'>
        9. Changes to this Policy
      </h2>
      <p className='indent-20'>
        We may update this Privacy Policy periodically. The "Effective Date" at
        the beginning of the policy will indicate when the most recent revisions
        were made.
      </p>

      <h2 className='text-2xl mt-4 font-bold text-gray-800'>10. Contact Us</h2>
      <p className='indent-20'>
        If you have questions or concerns about this Privacy Policy or your
        personal information, please contact us at{" "}
        <a href='mailto:info@qualmission.com' className='text-blue-500'>
          info@qualmission.com
        </a>
        .
      </p>
    </div>
  );
}

export default Privacy;
