const DigitalTransform = () => {
  const data = [
    {
      title: "",
      items: [
        {
          head: "Data Warehouse Solutions",
          desc: "Unlock the power of your data with our state-of-the-art Data Warehouse Solutions. In an era where data is king, Qualmission empowers you to harness its potential for strategic decision-making. Our data architects and engineers design robust and scalable data warehouse solutions that consolidate, manage, and analyze your data efficiently. From data integration to real-time analytics, we provide the infrastructure needed to transform raw data into actionable insights.",
        },
        {
          head: "Innovation at its Core",
          desc: "We stay at the forefront of technological advancements to ensure your business stays ahead of the curve.",
        },
        {
          head: "Tailored Solutions",
          desc: "Our approach is not one-size-fits-all. We understand your unique challenges and craft solutions that align with your specific goals.",
        },
        {
          head: "Expert Team",
          desc: "A team of seasoned professionals dedicated to excellence in every aspect of digital transformation.",
        },
        {
          head: "Proven Track Record",
          desc: "Years of successful projects and satisfied clients attest to our commitment to delivering results.",
        },
      ],
    },
  ];

  return (
    <div className='w-full glass p-8'>
      <div className='grid '>
        <h1 className='mb-4 text-4xl lg:text-5xl font-extrabold leading-none tracking-tight text-gray-900 text-center'>
          Digital Transformation Solutions
        </h1>
        <p className='mb-6 text-xl leading-8 font-normal text-gray-500 sm:px-16 xl:px-48 indent-20'>
          Welcome to the forefront of digital evolution with Qualmission, where
          we pave the way for your organization's seamless transition into the
          future. As a leading provider of Digital Transformation Solutions,
          Qualmission specializes in crafting innovative and tailored strategies
          that propel your business towards unparalleled success. Explore our
          cutting-edge offerings in Data Warehouse Solutions.
        </p>
      </div>
      <div className='lg:px-24'>
        {data.map((item, index) => (
          <div key={index}>
            <h2 className='text-2xl font-bold text-slate-800 mt-8'>
              {item.title}
            </h2>
            <div className='grid grid-cols-1 gap-4 mt-4'>
              {item.items.map((item, index) => (
                <div key={index}>
                  <h3 className='text-xl font-semibold text-slate-600'>
                    {item.head}
                  </h3>
                  <p className='indent-10 mt-2 text-slate-500 first-letter:font-bold first-letter:tracking-widest'>
                    {item.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <figure className='w-full py-16 px-8 text-center'>
        <div className='flex items-center mb-4 text-yellow-300'>
          {["", "", "", "", ""].map((item, index) => (
            <svg
              className='w-5 h-5 me-1'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 22 20'
            >
              <path d='M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z' />
            </svg>
          ))}
        </div>
        <blockquote>
          <p className='text-lg font-semibold  text-gray-700 italic'>
            <svg
              className='w-5 h-5  text-gray-400'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 18 14'
            >
              <path d='M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z' />
            </svg>
            Embark on your digital transformation journey with confidence.
            Qualmission is your trusted partner in navigating the complexities
            of the digital landscape. Contact us today, and let's shape the
            future of your business together.
          </p>
        </blockquote>
        <figcaption className='flex items-center float-right '>
          <div className='flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-300 dark:divide-gray-700'>
            <cite className='pe-3 text-lg text-sky-600 font-bold'>CEO</cite>
            <cite className='ps-3 text-sm font-bold text-gray-500 '>
              Qualmission
            </cite>
          </div>
        </figcaption>
      </figure>
    </div>
  );
};

export default DigitalTransform;
