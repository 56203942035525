const SoftwareDevelopment = () => {
  const data = [
    {
      title: "Our Approach to UI/UX Design",
      items: [
        {
          head: "User-Centric Design Philosophy",
          desc: "At Qualmission, we believe in putting users at the center of the design process. Our team meticulously studies user behaviors, preferences, and needs to create interfaces that resonate with your audience. Through empathetic design thinking, we craft solutions that not only meet but exceed user expectations.",
        },
        {
          head: "Responsive and Adaptive Interfaces",
          desc: "In a multi-device world, responsiveness is non-negotiable. Our UI/UX design solutions ensure that your digital platforms seamlessly adapt to various screen sizes and devices. From desktops to smartphones, we create consistent and engaging experiences across the entire spectrum.",
        },
        {
          head: "Seamless User Journeys",
          desc: "Navigating your digital space should be an intuitive and delightful experience. We map out user journeys, identifying touchpoints and optimizing pathways to enhance user interactions. By minimizing friction and maximizing engagement, we guide users through a seamless and enjoyable experience.",
        },
        {
          head: "Prototyping and Testing",
          desc: "Before final implementation, we bring designs to life through prototypes. This iterative process allows us to gather feedback, refine interactions, and address any potential issues. Rigorous testing ensures that the end product not only looks stunning but performs flawlessly.",
        },
        {
          head: "Continuous Optimization",
          desc: "The digital landscape evolves, and so should your user interfaces. We implement data-driven insights and user feedback to continuously optimize designs. Our commitment to ongoing improvement ensures that your UI/UX remains cutting-edge and aligns with changing user expectations.",
        },
      ],
    },
    {
      title: "Tailored UI/UX Solutions for Every Business",
      items: [
        {
          head: "E-Commerce Platforms",
          desc: "Optimize your online store with visually appealing and user-friendly interfaces. We design e-commerce solutions that not only showcase your products but also provide a seamless shopping experience, driving conversions and customer loyalty.",
        },
        {
          head: "Corporate Websites",
          desc: "Make a lasting impression with a corporate website that reflects your brand identity. Our UI/UX design solutions ensure that your online presence communicates professionalism, trustworthiness, and innovation.",
        },
        {
          head: "Mobile Applications",
          desc: "In the mobile-first era, we craft mobile app interfaces that captivate users from the first interaction. Whether iOS or Android, our designs prioritize usability and engagement to enhance the mobile experience.",
        },
        {
          head: "SaaS Platforms",
          desc: "For software as a service, the interface is the gateway to functionality. Our UI/UX solutions for SaaS platforms focus on clarity, simplicity, and efficiency, ensuring users can effortlessly navigate and utilize your software.",
        },
      ],
    },
    {
      title: "Why Choose Qualmission for UI/UX Design?",
      items: [
        {
          head: "Design Excellence",
          desc: "Our team of seasoned designers brings creativity and expertise to every project, ensuring visually stunning and effective UI/UX solutions.",
        },
        {
          head: "User-Centric Focus",
          desc: "We prioritize user needs, behaviors, and expectations to create designs that resonate and deliver a positive impact on your audience.",
        },
        {
          head: "Innovation in Every Detail",
          desc: "From micro-interactions to overall aesthetics, we infuse innovation into every aspect of our designs, setting your brand apart in the digital landscape.",
        },
        {
          head: "Proven Success",
          desc: "Years of experience and a portfolio of successful projects attest to our ability to deliver UI/UX solutions that drive results.",
        },
      ],
    },
  ];

  return (
    <div className='w-full glass p-8'>
      <div className='grid'>
        <h1 className='mb-4 text-4xl lg:text-5xl font-extrabold leading-none tracking-tight text-gray-900 text-center'>
          Design Solutions
        </h1>
        <p className='mb-6 text-xl leading-8 font-normal text-gray-500 sm:px-16 xl:px-48 indent-20'>
          Elevate User Experiences with Qualmission's UI/UX Design Solutions
          Welcome to Qualmission, where we redefine the digital landscape
          through visionary UI/UX design solutions that transform user
          experiences into unforgettable journeys. Our commitment is to elevate
          your brand by combining aesthetics, functionality, and user-centric
          design principles. Explore how our UI/UX solutions can propel your
          digital presence to new heights.
        </p>
      </div>
      <div className='lg:px-24'>
        {data.map((item, index) => (
          <div key={index}>
            <h2 className='text-2xl font-bold text-slate-800 mt-8'>
              {item.title}
            </h2>
            <div className='grid grid-cols-1 gap-4 mt-4'>
              {item.items.map((item, index) => (
                <div key={index}>
                  <h3 className='text-xl font-semibold text-slate-600'>
                    {item.head}
                  </h3>
                  <p className='mt-2 indent-10 text-slate-500 first-letter:font-bold first-letter:tracking-widest'>
                    {item.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <figure className='w-full py-16 px-8 text-center'>
        <div className='flex items-center mb-4 text-yellow-300'>
          {["", "", "", "", ""].map((item, index) => (
            <svg
              className='w-5 h-5 me-1'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 22 20'
            >
              <path d='M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z' />
            </svg>
          ))}
        </div>
        <blockquote>
          <p className='text-lg font-semibold  text-gray-700 italic'>
            <svg
              className='w-5 h-5  text-gray-400'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 18 14'
            >
              <path d='M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z' />
            </svg>
            Transform your digital presence with Qualmission's UI/UX design
            solutions. Contact us today to embark on a journey where design
            meets functionality, creating experiences that leave a lasting
            impression on your audience. Let's craft a design narrative that
            defines the future of your brand.
          </p>
        </blockquote>
        <figcaption className='flex items-center float-right '>
          <div className='flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-300 dark:divide-gray-700'>
            <cite className='pe-3 text-lg text-sky-600 font-bold'>CEO</cite>
            <cite className='ps-3 text-sm font-bold text-gray-500 '>
              Qualmission
            </cite>
          </div>
        </figcaption>
      </figure>
    </div>
  );
};

export default SoftwareDevelopment;
