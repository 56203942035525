const Staffing = () => {
  const data = [
    {
      title: "",
      items: [
        {
          head: "Staff Augmentation",
          desc: "Our staff augmentation services are designed to seamlessly integrate skilled professionals into your existing teams. Whether you need temporary support for a specific project or long-term assistance, we provide access to a pool of highly qualified candidates to enhance your workforce. This flexible approach ensures that you can scale your team up or down based on project requirements.",
        },
        {
          head: "Permanent Staffing and Recruitment Services",
          desc: "Finding the right talent for your organization is crucial for sustained success. Our permanent staffing and recruitment services leverage industry expertise and cutting-edge technology to identify, attract, and secure top-tier professionals. We streamline the hiring process, saving you time and resources while ensuring that you make informed decisions about the individuals who will drive your business forward.",
        },
      ],
    },
  ];

  return (
    <div className='w-full glass p-8'>
      <div className='grid'>
        <h1 className='mb-4 text-4xl lg:text-5xl font-extrabold leading-none tracking-tight text-gray-900 text-center'>
          Recruitment Process Outsourcing
        </h1>
        <p className='mb-6 text-xl leading-8 font-normal text-gray-500 sm:px-16 xl:px-48 indent-20'>
          Welcome to Qualmission, your trusted partner in workforce solutions.
          As a premier Recruitment Process Outsourcing (RPO) provider, we offer
          a comprehensive suite of services to meet your staffing and talent
          acquisition needs. Our commitment is to deliver top-notch solutions
          that empower your business to thrive in today's dynamic market.
        </p>
      </div>
      <div className='lg:px-24'>
        {data.map((item, index) => (
          <div key={index}>
            <h2 className='text-2xl font-bold text-slate-800 mt-8'>
              {item.title}
            </h2>
            <div className='grid grid-cols-1 gap-4 mt-4'>
              {item.items.map((item, index) => (
                <div key={index}>
                  <h3 className='text-xl font-semibold text-slate-600'>
                    {item.head}
                  </h3>
                  <p className='indent-10 mt-2 text-slate-500 first-letter:font-bold first-letter:tracking-widest'>
                    {item.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <figure className='w-full py-16 px-8 text-center'>
        <div className='flex items-center mb-4 text-yellow-300'>
          {["", "", "", "", ""].map((item, index) => (
            <svg
              className='w-5 h-5 me-1'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 22 20'
            >
              <path d='M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z' />
            </svg>
          ))}
        </div>
        <blockquote>
          <p className='text-lg font-semibold  text-gray-700 italic'>
            <svg
              className='w-5 h-5  text-gray-400'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 18 14'
            >
              <path d='M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z' />
            </svg>
            Contact us today to explore how Qualmission can elevate your
            workforce strategy and contribute to the success of your business.
            Together, let's build a future where your team is equipped to
            conquer new heights.
          </p>
        </blockquote>
        <figcaption className='flex items-center float-right '>
          <div className='flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-300 dark:divide-gray-700'>
            <cite className='pe-3 text-lg text-sky-600 font-bold'>CEO</cite>
            <cite className='ps-3 text-sm font-bold text-gray-500 '>
              Qualmission
            </cite>
          </div>
        </figcaption>
      </figure>
    </div>
  );
};

export default Staffing;
