import { useState } from "react";
import { BsClipboardCheck } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import ReactCountryFlag from "react-country-flag";

const Career = () => {
  return (
    <div
      className='bg-sky-500 text-white w-full grid grid-cols-1 xl:grid-cols-2 my-16 rounded-2xl '
      id={"contact"}
    >
      <FeedBackForm />
      <ContactInfo />
    </div>
  );
};

const ContactInfo = () => {
  const [emailCopied, setEmailCopied] = useState(false);

  return (
    <div
      className='grid place-content-center font-serif p-12 bg-sky-800 rounded-r-2xl'
      data-aos='flip-down'
      data-aos-delay='500'
    >
      <div className='text-xl md:text-2xl font-black mt-8 md:mt-2 '>
        We are firm believers in performing ethical business operations and
        cultivating values-based relationships with clients, workers, and anyone
        else that joins us in the organization's growth.
      </div>
      <div className='mt-8 text-xl font-bold text-center tracking-wider'>
        Get in touch
      </div>

      <div className='mt-2 text-xl '>
        <div className='pb-8'>
          We will help you to find the right best Fit. We have new opening
          everyday! E-mail your resume now.
        </div>
        {/* <div className='mb-8'>E-mail your resume now.</div> */}
        <span className='flex text-lg'>
          <IoLocationOutline className='mr-2' />
          <ReactCountryFlag className='mr-2' countryCode='US' />
          <ReactCountryFlag className='mr-2' countryCode='IN' />
        </span>

        <a className='underline' href='mail:hr@qualmission.com'>
          hr@qualmission.com
        </a>
        <div
          className={`text-white rounded font-bold text-sm ${
            emailCopied ? "text-lime-400" : ""
          }`}
          onClick={() => {
            setEmailCopied(true);
            setTimeout(() => setEmailCopied(false), 2000);
            navigator.clipboard.writeText(" jraman@qualmission.com");
          }}
        >
          <BsClipboardCheck className='inline-block' />{" "}
          <span className={`${emailCopied ? "" : "hover:cursor-pointer"}`}>
            {emailCopied ? "Copied..!" : "Copy email"}
          </span>
        </div>
      </div>
    </div>
  );
};

const FeedBackForm = () => {
  const [inputs, setInputs] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    const token = "6c48dff7-95bb-4019-9291-10e160104d09";
    event.preventDefault();
    // inputs.project = "qm";
    // fetch("https://feedback-ri.vercel.app/api/feedback", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(inputs),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data?.message === "sucess") {
    //       setSubmitted(true);
    //       setError(false);
    //     } else {
    //       setSubmitted(false);
    //       setError(true);
    //     }
    //   });

    const body = `Name: ${inputs.name}\nEmai: ${inputs.email}\nPhone: ${inputs.phone}\nSubject: ${inputs.subject}\nMessage: ${inputs.message}\n`;

    window.Email.send({
      SecureToken: "fd8d54c2-9fe7-4bdb-a50b-6d84f0bee0d2",
      To: "jraman@qualmission.com",
      From: "admin@qualmission.in",
      Subject: "Website Query",
      Body: body,
    }).then((message) => {
      if (message === "OK") {
        setSubmitted(true);
        setError(false);
      } else {
        setSubmitted(false);
        setError(true);
      }
    });
  };
  return (
    <div
      className='grid place-content-center font-serif p-12'
      data-aos='flip-down'
      data-aos-delay='500'
    >
      <fieldset className='border-t-4 border-l-4 border-solid border-sky-800 p-8 px-16 rounded-tl-3xl '>
        <legend className='font-bold text-2xl px-4'>Contact Us</legend>
        <form className='w-full text-slate-800' onSubmit={handleSubmit}>
          {!submitted && (
            <>
              <input
                type='text'
                placeholder='Name'
                name='name'
                className='input input-bordered w-full bg-white mb-4'
                value={inputs.name || ""}
                onChange={handleChange}
              />
              <input
                type='email'
                placeholder='E-mail'
                name='email'
                className='input input-bordered w-full bg-white mb-4'
                pattern='[^@\s]+@[^@\s]+\.[^@\s]+'
                title='Invalid email address'
                value={inputs.email || ""}
                onChange={handleChange}
                required
              />
              <input
                type='tel'
                placeholder='Phone'
                name='phone'
                className='input input-bordered w-full  bg-white mb-4'
                value={inputs.phone || ""}
                onChange={handleChange}
                pattern='[0-9]{10}'
                title='Enter 10 Digit phone number'
              />
              <input
                type='text'
                placeholder='Subject'
                name='subject'
                className='input input-bordered w-full  bg-white mb-4 '
                value={inputs.subject || ""}
                onChange={handleChange}
              />
              <textarea
                name='message'
                placeholder='Message'
                className='textarea textarea-bordered textarea-lg w-full bg-white mb-4 h-64 leading-normal'
                value={inputs.message || ""}
                onChange={handleChange}
              ></textarea>
              <button className='w-full btn border-0 my-4 text-lg text-white bg-sky-800 transition-all duration-150 ease-in-out hover:bg-lime-400 hover:text-green-900'>
                Submit
              </button>
            </>
          )}
          {submitted && (
            <div
              className='p-4 mb-4 text-sm text-green-700 rounded-lg bg-green-200 font-bold'
              role='alert'
            >
              We have received your message and would like to thank you for your
              interest in QualMission. Someone will get back to you very soon.
            </div>
          )}
          {error && (
            <div
              className='p-4 mb-4 text-sm text-white rounded-lg bg-red-600 font-bold'
              role='alert'
            >
              <span className='font-medium'>OOP..!</span> Something went wrong
              try again.
            </div>
          )}
        </form>
      </fieldset>
    </div>
  );
};

export default Career;
