import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AOS from "aos";
// import "aos/dist/aos.css";

import Home from "./component/home";
import Privacy from "./component/privacy";
import PageNotFound from "./component/pagenotfound";
import Layout from "./component/Layout";
import AboutUs from "./component/aboutus";
import Staffing from "./component/services/Staffing";
import QAService from "./component/services/QAService";
import SoftwareDevelopment from "./component/services/SoftwareDevelopment.js";
import DigitalTransform from "./component/services/DigitalTransform";

function App() {
  useEffect(() => {
    AOS.init({
      delay: 300,
    });
  }, []);

  return (
    <div className='App w-full h-full bg-zinc-100 font-sans'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/privacy-policy' element={<Privacy />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/ps' element={<Staffing />} />
            <Route path='/qa' element={<QAService />} />
            <Route path='/sd' element={<SoftwareDevelopment />} />
            <Route path='/dt' element={<DigitalTransform />} />
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
