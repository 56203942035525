import Card from "../card";
import dt from "../../dt.jpg";
import ds from "../../ds.jpg";
import qa from "../../qas.jpg";
import rs from "../../rs.jpg";

const OurServices = () => {
  let service = [
    {
      img: rs,
      className: "text-red-600",
      title: "Recruitment Process Outsourcing",
      link: "ps",
    },
    {
      img: dt,
      className: "text-red-600",
      title: "Digital Transformation Solutions",
      link: "dt",
    },
    {
      img: qa,
      className: "text-blue-600",
      title: "QA Services",
      link: "qa",
    },
    {
      img: ds,
      className: "text-blue-600",
      title: "Design Solutions",
      link: "sd",
    },
  ];

  return (
    <div className='pb-16 bg-sky-800 rounded-xl bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10'>
      <div
        className='flex flex-wrap place-content-center text-5xl py-8 text-slate-700 font-bold'
        id={"whatwedo"}
      >
        What We Do
      </div>
      <div className='flex flex-wrap place-content-center'>
        {service.map((details, index) => (
          <Card
            key={index}
            img={details.img}
            title={details.title}
            link={details.link}
          />
        ))}
      </div>
    </div>
  );
};

export default OurServices;
