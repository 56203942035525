import { useEffect } from "react";
import BanerV2 from "../banner/banner.v2";
import OurServices from "../ourservices";
import WhyUs from "../whyus";

function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className='w-full font-sans'>
      <BanerV2 />
      <OurServices />
      <WhyUs />
    </div>
  );
}

export default Home;
