import ba1 from "../../ba1.jpg";
import ba2 from "../../ba2.jpg";
import ba3 from "../../ba3.jpg";
import { useEffect, useState } from "react";
import MovingComponent from "react-moving-text";

function BanerV2() {
  const images = [ba1, ba2, ba3];
  const head = [
    ["Specialized IT", "Recruitment Services"],
    ["Flexible Staffing", "Models for You"],
    ["Global Staffing", "Solutions Provider"],
  ];
  const desc = [
    "Qualmission specializes in IT recruitment in the USA and India. Count on us to source top IT talent for your success.",
    "Qualmission offers flexible staffing solutions for the USA and India. Whether it's contract, permanent, or more, we've got you covered.",
    "Qualmission LLC, your global partner for staffing solutions in the USA and India. Find the right talent to drive your success.",
  ];
  let [src, setSrc] = useState(0);

  useEffect(() => {
    let i = 0;
    const interval = setInterval(async () => {
      i++;
      if (i === images.length) {
        i = 0;
      }

      setSrc(i);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='w-full'>
      <img className='hidden' src={ba1} alt='banner' loading='eager' />
      <img className='hidden' src={ba2} alt='banner' loading='eager' />
      <img className='hidden' src={ba3} alt='banner' loading='eager' />
      <Slider src={images[src]} txt={head[src]} desc={desc[src]} keyEle={src} />
    </div>
  );
}

const Slider = ({ src, txt, desc, keyEle }) => {
  let arr = ["blur", "bounce", "flash", "spin", "swing"];
  let arr2 = ["fade", "fade-down", "fade-up", "fade-left", "fade-right"];
  let random = Math.floor(Math.random() * arr.length);
  return (
    <div key={keyEle}>
      <div
        className={`w-full h-screen absolute top-0 left-0 z-0 bg-black animate-${arr2[random]} animate-once animate-duration-1000`}
      >
        <img
          id={"hero"}
          src={src}
          alt='banner'
          className='w-full h-screen object-cover opacity-50 z-10'
          loading='eager'
        />
        {/* <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1440 320'
          className='absolute -bottom-4 lg:-bottom-20 text-blue-300'
        >
          <path
            fill='currentColor'
            d='M0,96L40,80C80,64,160,32,240,42.7C320,53,400,107,480,144C560,181,640,203,720,197.3C800,192,880,160,960,144C1040,128,1120,128,1200,138.7C1280,149,1360,171,1400,181.3L1440,192L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z'
          ></path>
        </svg> */}
      </div>
      <div className='absolute top-0 left-0 w-full h-screen '></div>
      <div
        className={`relative w-full h-screen z-0 flex items-center justify-center px-16 animate-${arr2[random]} animate-once animate-duration-1000 animate-delay-500 animate-normal`}
      >
        <WordAnimate head={txt} desc={desc} type={arr[random]} />
      </div>
    </div>
  );
};

const WordAnimate = ({ head, desc, type }) => {
  return (
    <div className='lg:w-1/3'>
      <h1 className='text-4xl lg:text-6xl font-extrabold tracking-wide text-sky-300'>
        {head.map((word, i) => (
          <div className='flex'>
            {word?.split(" ").map((item, ini) => (
              <span className={`flex ${ini === 0 ? `text-yellow-300` : ``}`}>
                {item?.split("").map((item, ind) => (
                  <MovingComponent
                    type={type}
                    duration={`1000ms`}
                    delay={`${ind * 100}ms`}
                    direction={"normal"}
                    timing={"ease"}
                    iteration={"1"}
                    fillMode={"none"}
                    key={ind + item}
                  >
                    {item}
                  </MovingComponent>
                ))}
                &nbsp;
              </span>
            ))}
          </div>
        ))}
      </h1>
      <p className='text-white tracking-widest pt-4 indent-20'>{desc}</p>
    </div>
  );
};
export default BanerV2;
