import { BiMedal } from "react-icons/bi";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { HiLightBulb } from "react-icons/hi";

const WhyUs = () => {
  let service = [
    {
      img: <BiMedal className='w-full h-72 scale-75 text-teal-300' />,
      className: "text-red-600",
      title: "Our Working Process",
      desc: "Experience the power of streamlined efficiency in our IT staffing and solutions company. We orchestrate the entire process from talent acquisition to project delivery ensuring a seamless journey marked by precision, flexibility, and triumph. Elevate your projects with our dedicated team, customized solutions, and unwavering commitment to excellence.",
      tags: ["quality", "discipline", "perfection"],
    },
    {
      img: <HiLightBulb className='w-full h-72 scale-75 text-yellow-300' />,
      className: "text-red-600",
      title: "Dedicated Team",
      desc: "Unleash the Potential of Expertise: Our dedicated team stands at the forefront of excellence in IT solutions. Committed to your success, our professionals bring a wealth of expertise to every project. Experience the collaborative synergy, tailored solutions, and unwavering dedication that define our team, driving your ventures to new heights.",
      tags: ["dedication", "teamwork", "performance"],
    },
    {
      img: <TfiHeadphoneAlt className='w-full h-72 scale-50 text-gray-300' />,
      className: "text-red-600",
      title: "24/7 Hours Support",
      desc: "Reliable Support Around the Clock: Our commitment goes beyond business hours. Experience the assurance of 24/7 support from our dedicated team. Whether it's troubleshooting, guidance, or urgent assistance, we stand ready to provide unwavering support at any hour. Your success is our priority, and our round-the-clock availability reflects that dedication.",
      tags: ["support", "assistance", "trouble-free"],
    },
  ];
  return (
    <div className='mt-24'>
      <div
        className='flex flex-wrap place-content-center text-5xl text-slate-700 w-full my-12 font-bold'
        id={"whyus"}
      >
        Why Us
      </div>
      {/* <div className='flex flex-wrap place-content-center'> */}
      <div className='flex flex-wrap place-content-center'>
        {service.map((details, index) => (
          <WhyUsCard
            key={index}
            img={details.img}
            className={details.className}
            title={details.title}
            desc={details.desc}
            tags={details.tags}
          />
        ))}
      </div>
    </div>
  );
};

const WhyUsCard = ({ img, title, desc, tags }) => {
  return (
    <div className='card w-96 bg-sky-700 shadow-xl image-full m-4'>
      <figure>{img}</figure>
      <div className='card-body flex'>
        <h2 className='card-title text-2xl flex place-content-center text-center'>
          {title}
        </h2>
        <p>{desc}</p>
        <div className='card-actions text-center'>
          {tags && (
            <div>
              {tags.map((tag, id) => (
                <span
                  key={id}
                  className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-bold text-blue-500 m-2'
                >
                  #{tag}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
