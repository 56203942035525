import NavBar from "./navbar";
import Footer from "./footer";
import { Outlet } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import { useEffect } from "react";
import Particles from "particlesjs";
import Career from "./contactus";

function Layout() {
  useEffect(() => {
    Particles.init({
      selector: ".background",
      color: ["#006F90", "#FFC300"],
      connectParticles: true,
      maxcParticles: 5000,
      sizeVariations: 3,
    });
  }, []);
  return (
    <div className='App w-full h-full font-sans'>
      <canvas
        className='background'
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
        }}
      />
      <NavBar />
      <div className='grid grid-cols-12'>
        <div className='col-span-2'></div>
        <div className='col-span-12 lg:col-span-8 pt-32 z-10'>
          <Outlet />
          <Career />
        </div>
        <div className='col-span-2'></div>
      </div>
      <Footer />
      <BackToTop />
    </div>
  );
}

const BackToTop = () => {
  return (
    <div
      className='fixed bottom-12 right-5 rounded-full bg-sky-900 p-4 text-lg text-sky-200 shadow-2xl transition duration-150 ease-in-out hover:bg-gray-700 z-50 hover:scale-125'
      onClick={() => {
        window.scrollTo({ top: 0 });
      }}
    >
      <FaArrowUp />
    </div>
  );
};

export default Layout;
