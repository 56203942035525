const QAService = () => {
  const data = [
    {
      title: "",
      items: [
        {
          head: "Manual Testing Excellence",
          desc: "Our skilled manual testing teams meticulously evaluate your software applications to identify potential issues that might impact user experience. With a keen eye for detail, we conduct thorough test cases, ensuring that every aspect of your application is scrutinized. From functionality to usability, our manual testing services leave no stone unturned.",
        },
        {
          head: "Automated Testing Precision",
          desc: "In the rapidly evolving world of software development, speed and precision are paramount. Our automated testing services leverage cutting-edge tools and frameworks to execute repetitive but essential test cases with efficiency. Automation not only accelerates the testing process but also enhances accuracy, allowing for faster release cycles without compromising on quality.",
        },
        {
          head: "Test Case Design and Execution",
          desc: "Our QA experts are adept at creating comprehensive test cases that align with your software requirements. From positive to negative scenarios, we cover all possible use cases to validate the robustness of your application. Test case execution is carried out meticulously, ensuring that every function performs as intended.",
        },
        {
          head: "Performance and Load Testing",
          desc: "Your software's performance under varying conditions is crucial for user satisfaction. Our QA services include performance and load testing to simulate real-world scenarios, identifying bottlenecks and optimizing your application for optimal speed and responsiveness.",
        },
        {
          head: "Security Testing",
          desc: "Protect your software and user data with our rigorous security testing. We employ industry-standard practices to uncover vulnerabilities, ensuring that your application is fortified against potential security threats.",
        },
        {
          head: "",
          desc: "",
        },
        {
          head: "",
          desc: "",
        },
      ],
    },
    {
      title: "Tailored QA Solutions for Every Industry",
      items: [
        {
          head: "Web Applications",
          desc: "From dynamic web platforms to e-commerce websites, our QA services are tailored to ensure the seamless functionality and performance of your web applications.",
        },
        {
          head: "Mobile Applications",
          desc: "In the mobile-centric landscape, our QA experts specialize in testing iOS and Android applications, guaranteeing a flawless user experience across diverse devices.",
        },
        {
          head: "Enterprise Software",
          desc: "For complex enterprise solutions, we conduct extensive testing to validate the functionality, security, and scalability of your software, ensuring it meets the highest standards.",
        },
        {
          head: "E-Commerce Platforms",
          desc: "Trust us to thoroughly test your e-commerce platform, identifying and addressing any issues that might affect the user journey and transaction processes.",
        },
        {
          head: "Why Choose Qualmission for QA Services?",
          desc: "Expertise in Testing: Our seasoned QA teams bring a wealth of experience and expertise to deliver comprehensive testing services.",
        },
        {
          head: "Agile Testing Practices:",
          desc: "We adapt to agile methodologies, ensuring that testing seamlessly integrates into your development processes for continuous delivery.",
        },
        {
          head: "Cutting-Edge Tools",
          desc: "Utilizing the latest tools and frameworks, we stay ahead of the curve to provide efficient and effective QA services.",
        },
        {
          head: "Scalability and Flexibility",
          desc: "Our QA solutions scale with your business, offering flexibility to accommodate evolving testing needs.",
        },
      ],
    },
  ];

  return (
    <div className='w-full glass p-8'>
      <div className='grid'>
        <h1 className='mb-4 text-4xl lg:text-5xl font-extrabold leading-none tracking-tight text-gray-900 text-center'>
          QA services
        </h1>
        <p className='mb-6 text-xl leading-8 font-normal text-gray-500 sm:px-16 xl:px-48 indent-20'>
          Welcome to the forefront of digital evolution with Qualmission, where
          we pave the way for your organization's seamless transition into the
          future. As a leading provider of Digital Transformation Solutions,
          Qualmission specializes in crafting innovative and tailored strategies
          that propel your business towards unparalleled success. Explore our
          cutting-edge offerings in Data Warehouse Solutions.
        </p>
      </div>
      <div className='lg:px-24'>
        {data.map((item, index) => (
          <div key={index}>
            <h2 className='text-2xl font-bold text-slate-800 mt-8'>
              {item.title}
            </h2>
            <div className='grid grid-cols-1 gap-4 mt-4'>
              {item.items.map((item, index) => (
                <div key={index}>
                  <h3 className='text-xl font-semibold text-slate-600'>
                    {item.head}
                  </h3>
                  <p className='indent-10 mt-2 text-slate-500 first-letter:font-bold first-letter:tracking-widest'>
                    {item.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <figure className='w-full py-16 px-8 text-center'>
        <div className='flex items-center mb-4 text-yellow-300'>
          {["", "", "", "", ""].map((item, index) => (
            <svg
              className='w-5 h-5 me-1'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 22 20'
            >
              <path d='M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z' />
            </svg>
          ))}
        </div>
        <blockquote>
          <p className='text-lg font-semibold  text-gray-700 italic'>
            <svg
              className='w-5 h-5  text-gray-400'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 18 14'
            >
              <path d='M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z' />
            </svg>
            Ensure the reliability and quality of your software applications
            with Qualmission's comprehensive QA services. Contact us today to
            embark on a journey where your software's performance is synonymous
            with excellence. Let's build a future where your digital solutions
            set the benchmark for quality in your industry.
          </p>
        </blockquote>
        <figcaption className='flex items-center float-right '>
          <div className='flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-300 dark:divide-gray-700'>
            <cite className='pe-3 text-lg text-sky-600 font-bold'>CEO</cite>
            <cite className='ps-3 text-sm font-bold text-gray-500 '>
              Qualmission
            </cite>
          </div>
        </figcaption>
      </figure>
    </div>
  );
};

export default QAService;
